import axios from "axios";
import * as Bowser from "bowser";

import { UserType } from "./enums/user-type.enum";
import { createHandover } from "./handover";
import { BotResponseModel, IAttachmentData, IExpressionData, IHistoryData } from "./models/bot-response.model";
import { DeviceInfoModel } from "./models/device-info.model";
import { EventModel } from "./models/event.model";
import { IHandoverProps } from "./models/handoverProps.model";
import { SessionInfoModel } from "./models/session-info.model";
import { WidgetConfigModel } from "./models/widget-config.model";
import LocalStorage from "./util/localStorage";
import SessionStorage from "./util/sessionStorage";
import TimerQueue from "./util/timer-queue";
import { escapeStringContent } from "./util/util";

export class BotsSDK {
    baseUrl: string;
    sessionInfo: SessionInfoModel;
    handover: {
        sendAttachement: (event: Event) => void;
        sendMessage: (msg: string, name: string) => void;
        sendTyping: (state: boolean) => void;
        stop: () => void;
    };
    history: IHistoryData[] = [];
    messages: BotResponseModel[] = [];

    onPropRecieved: (props: IHandoverProps) => void;
    onNewMessage: (msg: BotResponseModel) => void;
    onTyping: (typing: boolean) => void;
    sessionStarted: (started: boolean) => void;
    onRequestPending: (pending: boolean) => void;

    restartingSession: boolean = false;

    private widgetConfig: WidgetConfigModel;

    constructor(public widgetId: string, public knowledge: {} = {}) {
        this.baseUrl = process.env.API_BASE_URL;
        this.sessionInfo = new SessionInfoModel();
        this.widgetId = widgetId;
        this.knowledge = knowledge;
    }

    async startSession(chatbotId: string, events: EventModel[] = []): Promise<void | SessionInfoModel> {
        events.push(new EventModel("URL", window.location.href));

        let storedSessionLocal = null;
        let params = {};
        let initial_entities = this.knowledge;
        const url = `${this.baseUrl}/sessions/${chatbotId}/`;

        if (this.widgetConfig.keep_session) {
            storedSessionLocal = JSON.parse(LocalStorage.get(this.widgetConfig.chatbot_id));
        }

        try {
            if ((Date.parse(new Date().toString()) - Date.parse(storedSessionLocal?.created)) < 12 * 1000 * 60 * 60) {
                params = { session_id: storedSessionLocal.session };
            } else if (!!this.widgetConfig.initial_session_id && !this.restartingSession) {
                params = { session_id: this.widgetConfig.initial_session_id };
            } else if (!!this.widgetConfig.external_user_id && this.restartingSession) {
                params = { external_user_id: this.widgetConfig.external_user_id };
                initial_entities = { ...initial_entities, ...this.widgetConfig.initial_knowledge };
            }
        } catch (error) {
            console.log("Failed to resume session");
        }

        try {
            const data = {initial_entities, events, ...params, ...Bowser.parse(window.navigator.userAgent)};
            const response = await axios({method: "post", url, data});

            if (response.data.hasOwnProperty("session")) {
                LocalStorage.add(response.data.chatbot, JSON.stringify({
                    "session": response.data.session,
                    "created": new Date().toString()
                }))
                this.sessionInfo = response.data;
                this.handleInitialMessages(response.data.messages);
                this.handlePreviousSessionMessages(response.data.previous_turns);
                this.sessionStarted(true);
            }
            return response.data;
        } catch (e) {
            return console.log("Failed to start session");
        }
    }

    private handleInitialMessages(messages: BotResponseModel[]) {
        if (messages.length) {
            this.onRequestPending(true);
            this._onBotResponseReceived(messages);
        }
    }

    private handlePreviousSessionMessages(previous_turns: BotResponseModel[]) {
        let messages: BotResponseModel[] = [];
        if (previous_turns?.length) {
            messages = previous_turns.filter((turn: { type: string }, index: number) => turn.type !== "choices" || index === previous_turns.length - 1);
        }

        if (messages.length) {
            this.onRequestPending(true);
            this._onBotResponseReceived(messages, false);
        }
    }

    private logHandoverToBackend() {
        axios({method: "post", url: `${this.baseUrl}/chat/${this.sessionInfo.chatbot}/${this.sessionInfo.session}/handover_initiated/`});
    }

    async restartSession(): Promise<void | SessionInfoModel> {
        this.restartingSession = true;
        if (this.handover) {
            this.handover.stop();
            this.handover = null;
        }
        SessionStorage.clear();

        if (this.widgetConfig.keep_session) {
            LocalStorage.remove(this.widgetConfig.chatbot_id)
        }
        return this.startSession(this.sessionInfo.chatbot);
    }

    async getDeviceInfo(): Promise<void | DeviceInfoModel> {
        return Bowser.parse(window.navigator.userAgent) as DeviceInfoModel;
    }

    async getConfig(): Promise<void | WidgetConfigModel> {
        const url = `${this.baseUrl}/widgets/${this.widgetId}/`;
        const params = this._getQueryVariables();
        try {
            const response = await axios({ method: "get", params, url });
            this.widgetConfig = response.data;
            return response.data;
        } catch (e) {
            return console.log("Failed to get config");
        }
    }

    setSessionStarted(callback: (typing: boolean) => void) {
        this.sessionStarted = callback;
    }

    setOnTyping(callback: (typing: boolean) => void) {
        this.onTyping = callback;
    }

    setOnRequestPending(callback: (pending: boolean) => void) {
        this.onRequestPending = callback;
    }

    setOnNewMessage(callback: (msg: BotResponseModel) => void) {
        this.onNewMessage = callback;
    }

    setOnPropRecieved(callback: (props: IHandoverProps) => void) {
        this.onPropRecieved = callback;
    }

    async sendTyping(state: boolean) {
        this.handover.sendTyping(state);
    }

    async sendAttachement(event: Event) {
        if (this.handover) {
            this.handover.sendAttachement(event);
            this._sendHandoverDataToBot("Attachment send: " + (event.target as HTMLInputElement).files[0].name, "user");
        }
    }

    async sessionConplete() {
        if (this.widgetConfig.keep_session) {
            LocalStorage.remove(this.widgetConfig.chatbot_id)
        }
    }

    async sendMessage(message: string, payload: {}) {
        let data: { payload: {}; message?: string } = { payload };

        if (message) {
            message = escapeStringContent(message);
            data = { message, ...data };
        }

        const url = `${this.baseUrl}/chat/${this.sessionInfo.chatbot}/${this.sessionInfo.session}/`;
        if (this.handover) {
            this.handover.sendMessage(message, "handover chatbot");
            this._sendHandoverDataToBot(message, "user");
        } else {
            this.onRequestPending(true);
            try {
                const response = await axios({
                    data,
                    method: "post",
                    url
                });

                if (!!response.data.length && response.data[0].type === "timeout") {
                    this._handleSessionTimeout(data);
                } else {
                    this._onBotResponseReceived(response.data || []);
                }
            } catch (e) {
                this.onRequestPending(false);
                this._handleError(e);
            }
        }
    }

    private async _handleSessionTimeout(data: { payload: {}; message?: string }) {
        if (!this.widgetConfig.new_session_on_expiry) {
            const url = `${this.baseUrl}/chat/${this.sessionInfo.chatbot}/${this.sessionInfo.session}/`;
            try {
                await axios({
                    data: { payload: { type: "reopen" } },
                    method: "post",
                    url
                });

                const messageResponse = await axios({
                    data: { ...data },
                    method: "post",
                    url
                });

                const messages = messageResponse.data;
                this._onBotResponseReceived(messages);
            } catch (e) {
                this._handleError(e);
            }
        } else {
            try {
                SessionStorage.clear();
                const url = `${this.baseUrl}/sessions/${this.widgetConfig.chatbot_id}/`;
                const response = await axios({
                    method: "post",
                    url,
                    data: { initial_entities: this.knowledge }
                });

                const notification = new BotResponseModel(
                    {
                        reply: this.widgetConfig.session_expiry_text
                    } as IExpressionData,
                    UserType.System,
                    "expression"
                );

                const messages = [notification, ...response.data.messages];

                this._onBotResponseReceived(messages);
                this.sessionInfo = response.data;
                return response.data;
            } catch (e) {
                return console.log("Failed to start session");
            }
        }
    }

    private _onBotResponseReceived(messages: BotResponseModel[], useTypingDelay = true) {
        this.setHistory(messages);

        if (!this.handover) {
            this.checkMessagesForHandover(messages);
            const timerQueue = new TimerQueue(this.onRequestPending);
            if (messages.length) {
                messages.forEach((item: BotResponseModel) => this.fillTimerQueue(item, timerQueue, useTypingDelay));
            } else {
                this.onRequestPending(false);
            }
        }
    }

    private fillTimerQueue(item: BotResponseModel, timerQueue: TimerQueue, useTypingDelay: boolean) {
        this.messages.push(item);
        if (item.from !== "system") {
            item.from = UserType.Bot;
        }
        if (item.type === "user") {
            item.from = UserType.User;
        }
        if (item.type === "rep") {
            item.from = UserType.Agent;
        }
        timerQueue.addTask(this.onNewMessage, item, useTypingDelay ? this.widgetConfig.typing_delay : 0, this.onTyping);
    }

    private checkMessagesForHandover(messages: BotResponseModel[]) {
        const handoverResponse = messages.find((item: BotResponseModel) => item.type === "handover");
        if (handoverResponse && !this.handover) {
            this.handover = createHandover(
                handoverResponse,
                (msg: BotResponseModel) => this._onNewMessage(msg),
                () => this._onEnd(),
                (props: IHandoverProps) => this._onPropRecieved(props),
                (typing: boolean) => this.onTyping(typing),
                this.history,
                this.sessionInfo
            );
            this.logHandoverToBackend();
        }
    }

    private setHistory(messages: BotResponseModel[]) {
        const historyResponse = messages.find((item: BotResponseModel) => item.type === "history");
        if (historyResponse && !this.handover) {
            this.history = historyResponse.data as IHistoryData[];
        }
    }

    async sendTranscript(payload: {}) {
        const url = `${this.baseUrl}/chat/${this.sessionInfo.chatbot}/${this.sessionInfo.session}/`;
        try {
            await axios({
                data: { payload },
                method: "post",
                url
            });
        } catch (e) {
            this._handleError(e);
        }
    }

    private _onNewMessage(msg: BotResponseModel) {
        this._sendHandoverDataToBot(
            msg.type === "attachment" ? "Attachment send: " + (msg.data as IAttachmentData).reply.name : (msg.data as IExpressionData).reply,
            "rep"
        );
        this.onNewMessage(msg);
    }

    private _onPropRecieved(props: IHandoverProps) {
        this.onPropRecieved(props);
    }

    private _onEnd() {
        SessionStorage.clear();

        if (this.widgetConfig.keep_session) {
            LocalStorage.remove(this.widgetConfig.chatbot_id)
        }

        if (this.handover) {
            this.handover.stop();
            this.handover = null;
        }
        this.sendMessage("Handover ended", {});
    }

    private _sendHandoverDataToBot(message: string, agent: string) {
        const url = `${this.baseUrl}/chat/${this.sessionInfo.chatbot}/${this.sessionInfo.session}/`;
        axios({ data: [{ agent, message }], method: "put", url })
            .then((): void => undefined)
            .catch(this._handleError);
    }

    private _handleError(err: any) {
        console.log("Failed to send message", err);
    }

    private _getQueryVariables() {
        const queryParams: { [key: string]: string } = {};
        const query = window.location.search.substring(1);
        const vars = query.split("&");
        vars.forEach((param) => {
            const pair = param.split("=");
            queryParams[pair[0]] = pair[1];
        });
        delete queryParams[""];
        return queryParams;
    }
}

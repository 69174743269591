export const translations = {
    en: {
        openMenu: "Open menu",
        closeMenu: "Close menu",
        refresh: "Refresh",
        refreshTheWidget: "Refresh the widget",
        close: "Close",
        closeTheWidget: "Close the widget",
        toggleFullscreen: "Toggle fullscreen",
        receiveTranscript: "Receive transcript of the conversation",
        receiveTranscriptMail: "Receive transcript by mail.",
        email: "Email",
        cancel: "Cancel",
        send: "Send",
        open: "Open",
        user: "user",
        bot: "bot",
        agent: "agent",
        download: "download",
        knowledgebaseItem: "knowledgebase item",
        wasThisArticleUseful: "Was this article useful?",
        sendButton: "Send message",
        openTheChatbot: "Open the chatbot",
        closeTheChatbot: "Close the chatbot",
        widgetIframeTitle: "Chat with the chatbot",
        launcherIframeTitle: "Start the chatbot",
        isTyping: "Is typing",
        addAttachment: "Add attachment",
        sendAttachment: "Send attachment",
        removeAttachment: "Remove attachment",
        selectYourOption: "Select your option",
        redoChoice: "Redo choice",
        messageFrom: "Message from",
        giveFeedback: "Give feedback",
        noFeedback: "No feedback",
        thankYou: "Thank you.",
        fileToLarge: "File is to large to preview",
        goto: "Go to"
    },
    nl: {
        openMenu: "Open menu",
        closeMenu: "Sluit menu",
        refresh: "Vernieuwen",
        refreshTheWidget: "Vernieuw de widget",
        close: "Sluiten",
        closeTheWidget: "Sluit de widget",
        toggleFullscreen: "Schakel volledig scherm in/uit",
        receiveTranscript: "Ontvang transcript van het gesprek",
        receiveTranscriptMail: "Ontvang transcript per mail",
        email: "E-mail",
        cancel: "Annuleer",
        send: "Verzend",
        open: "Open",
        user: "gebruiker",
        bot: "chatbot",
        agent: "medewerker",
        download: "download",
        knowledgebaseItem: "kennisbank item",
        wasThisArticleUseful: "Was dit artikel nuttig?",
        sendButton: "Verstuur bericht",
        openTheChatbot: "Open de chatbot",
        closeTheChatbot: "Sluit de chatbot",
        widgetIframeTitle: "Chat met de chatbot",
        launcherIframeTitle: "Start de chatbot",
        isTyping: "Aan het typen",
        addAttachment: "Bijlage toevoegen",
        sendAttachment: "Bijlage versturen",
        removeAttachment: "Bijlage verwijderen",
        selectYourOption: "maak uw keuze",
        redoChoice: "Kies opnieuw",
        messageFrom: "Bericht van",
        giveFeedback: "Geef feedback",
        noFeedback: "Geen feedback",
        thankYou: "Dank u.",
        fileToLarge: "Bestand is te groot om te bekijken",
        goto: "Ga naar"
    },
    fr: {
        openMenu: "Ouvrir le menu",
        closeMenu: "Fermer le menu",
        refresh: "Rafraîchir",
        refreshTheWidget: "Rafraîchir le widget",
        close: "Fermer",
        closeTheWidget: "Fermer le widget",
        toggleFullscreen: "Basculer en plein écran",
        receiveTranscript: "Recevoir la transcription de la conversation",
        receiveTranscriptMail: "Recevoir la transcription par e-mail.",
        email: "E-mail",
        cancel: "Annuler",
        send: "Envoyer",
        open: "Ouvrir",
        user: "utilisateurs",
        bot: "chatbot",
        agent: "mécanicien",
        download: "télécharger",
        knowledgebaseItem: "élément de la base de connaissances",
        wasThisArticleUseful: "Cet article était-il utile ?",
        sendButton: "Envoyer un message",
        openTheChatbot: "Ouvrir le chatbot",
        closeTheChatbot: "Fermer le chatbot",
        widgetIframeTitle: "Discuter avec le chatbot",
        launcherIframeTitle: "Démarrer le chatbot",
        isTyping: "est en train d'écrire",
        addAttachment: "Ajouter une pièce jointe",
        sendAttachment: "Envoyer une pièce jointe",
        removeAttachment: "Supprimer la pièce jointe",
        selectYourOption: "Sélectionnez votre option",
        redoChoice: "Refaire le choix",
        messageFrom: "Message de",
        giveFeedback: "Donner son avis",
        noFeedback: "Pas de retour d'information",
        thankYou: "Merci.",
        fileToLarge: "Le fichier est trop volumineux pour être prévisualisé",
        goto: "Aller à"
    },
    de: {
        openMenu: "Menü öffnen",
        closeMenu: "Menü schließen",
        refresh: "Aktualisieren",
        refreshTheWidget: "Widget aktualisieren",
        close: "Schließen",
        closeTheWidget: "Widget schließen",
        toggleFullscreen: "Vollbildmodus umschalten",
        receiveTranscript: "Transkript des Gesprächs erhalten",
        receiveTranscriptMail: "Transkript per E-Mail erhalten",
        email: "E-Mail",
        cancel: "Abbrechen",
        send: "Senden",
        open: "Öffnen",
        user: "benutzer",
        bot: "chatbot",
        agent: "mitarbeiter",
        download: "herunterladen",
        knowledgebaseItem: "Wissensdatenbankelement",
        wasThisArticleUseful: "War dieser Artikel hilfreich?",
        sendButton: "Nachricht senden",
        openTheChatbot: "Chatbot öffnen",
        closeTheChatbot: "Chatbot schließen",
        widgetIframeTitle: "Mit dem Chatbot chatten",
        launcherIframeTitle: "Chatbot starten",
        isTyping: "tippt",
        addAttachment: "Anhang hinzufügen",
        sendAttachment: "Anhang senden",
        removeAttachment: "Anhang entfernen",
        selectYourOption: "Wählen Sie Ihre Menüoption",
        redoChoice: "Auswahl wiederholen",
        messageFrom: "Nachricht von",
        giveFeedback: "Feedback geben",
        noFeedback: "Kein Feedback",
        thankYou: "Danke.",
        fileToLarge: "Die Datei ist zu groß für die Vorschau",
        goto: "Gehe zu"
    },
    es: {
        openMenu: "Abrir menú",
        closeMenu: "Cerrar menú",
        refresh: "Actualizar",
        refreshTheWidget: "Actualizar el widget",
        close: "Cerrar",
        closeTheWidget: "Cerrar el widget",
        toggleFullscreen: "Alternar pantalla completa",
        receiveTranscript: "Recibir transcripción de la conversación",
        receiveTranscriptMail: "Recibir transcripción por correo",
        email: "Correo electrónico",
        cancel: "Cancelar",
        send: "Enviar",
        open: "Abrir",
        user: "usuarios",
        bot: "chatbot",
        agent: "trabajadores",
        download: "descargar",
        knowledgebaseItem: "elemento de la base de conocimientos",
        wasThisArticleUseful: "¿Fue útil este artículo?",
        sendButton: "Enviar mensaje",
        openTheChatbot: "Abrir el chatbot",
        closeTheChatbot: "Cerrar el chatbot",
        widgetIframeTitle: "Chatear con el chatbot",
        launcherIframeTitle: "Iniciar el chatbot",
        isTyping: "esta escribiendo",
        addAttachment: "Añadir un adjunto",
        sendAttachment: "Enviar archivo adjunto",
        removeAttachment: "Eliminar archivo adjunto",
        selectYourOption: "Seleccione su opción",
        redoChoice: "Rehacer elección",
        messageFrom: "Mensaje de",
        giveFeedback: "Dar opinion",
        noFeedback: "Sin realimentación",
        thankYou: "Gracias.",
        fileToLarge: "El archivo es demasiado grande para obtener una vista previa",
        goto: "Ir a"
    }
}
